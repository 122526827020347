import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  createAnOrder,
  deleteUserCart,
  getUserCart,
  resetState,
} from "../features/user/userSlice";
import {FlutterWaveButton, useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

const shippingSchema = yup.object({
  amount: yup.number().required("amount is Required").positive().integer(),
});

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartState = useSelector((state) => state?.auth?.cartProducts);
  const authState = useSelector((state) => state?.auth);
  const [totalAmount, setTotalAmount] = useState(null);
  const [cartProductState, setCartProductState] = useState([]);

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + Number(cartState[index].quantity) * cartState[index].price;
      setTotalAmount(sum);
    }
  }, [cartState]);

  const getTokenFromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getUserCart(config2));
  }, [dispatch]);

  useEffect(() => {
    if (
      authState?.orderedProduct?.order !== null &&
      authState?.orderedProduct?.success === true
    ) {
      navigate("/my-orders");
    }
  }, [authState, navigate]);

  useEffect(() => {
    let items = [];
    for (let index = 0; index < cartState?.length; index++) {
      items.push({
        product: cartState[index]?.productId._id,
        quantity: cartState[index]?.quantity,
        color: cartState[index]?.color?._id,
        price: cartState[index]?.price,
      });
    }
    setCartProductState(items);
  }, [cartState]);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address: "",
      state: "",
      city: "",
      country: "",
      amount: "",
      other: "",
    },
    validationSchema: shippingSchema,
    onSubmit: (values) => {
      localStorage.setItem("address", JSON.stringify(values));
      // Payment is triggered by the FlutterWaveButton
      // No need to manually trigger payment in onSubmit anymore, button handles it
    },
  });

  const pay_config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now().toString(),
    amount: formik.values.amount || 0,
    currency: "UGX",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: authState?.user?.email || "",
      phone_number: authState?.user?.mobile || "",
      name: `${authState?.user?.firstname} ${authState?.user?.lastname}`,
    },
    customizations: {
      title: "Wallet Deposit for"+ authState?.user?.firstname + " "+ authState?.user?.lastname,
      description: "Wallet Deposit",
      logo: "images/brand-08.png",
    },
     callback: (response) => {
       console.log(response);
       handlePaymentCompletion(response);
      closePaymentModal()
    },
    onClose: () => {
        console.log("Payment closed");
    },
  };


  const fwConfig = {
    ...pay_config,
    text: 'Make Deposit',
    type: "button", // Explicitly set button type to "button" to avoid Formik submit warning
  };


  const handlePaymentCompletion = (response) => {
    if (response.status === "successful") {
      const paymentInfo = {
        flutterwavePaymentId: response.transaction_id,
        flutterwaveOrderId: response.tx_ref,
      };

      dispatch(
        createAnOrder({
          totalPrice: totalAmount,
          totalPriceAfterDiscount: totalAmount,
          orderItems: cartProductState, // Review if needed for wallet deposit
          paymentInfo: paymentInfo,
          shippingInfo: JSON.parse(localStorage.getItem("address")), // Review if needed
        })
      );
      dispatch(deleteUserCart(config2)); // Review if needed
      localStorage.removeItem("address"); // Review if needed
      dispatch(resetState()); // Review if needed - maybe reset specific state parts
      alert("Payment Successful! Wallet updated.");
    } else {
      alert("Payment failed. Please try again.");
    }
    closePaymentModal();
  };


  return (
    <>
      <Container class1="checkout-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-7">
            <div className="checkout-left-data">
              <h3 className="website-name">Deposit Into Wallet</h3>
              <nav
                style={{ "--bs-breadcrumb-divider": ">" }}
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link className="text-dark total-price" to="/home">
                      Home
                    </Link>
                  </li>
                </ol>
              </nav>

              <p className="user-details total">
                {authState?.user?.firstname} {authState?.user?.lastname}
              </p>
              <p className="user-details total">
                {authState?.user?.email}
              </p>

              <form
                onSubmit={formik.handleSubmit}
                className="d-flex gap-15 flex-wrap justify-content-between"
              >
                <div className="flex-grow-1">
                  <input
                    type="number"
                    placeholder="Enter Deposit Amount"
                    className="form-control"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange("amount")}
                    onBlur={formik.handleBlur("amount")}
                  />
                  <div className="error ms-2 my-1">
                    {formik.touched.amount && formik.errors.amount}
                  </div>
                </div>

                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to="/home" className="text-dark">
                    </Link>
                    <FlutterWaveButton className="button" {...fwConfig} />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-5">
            <div className="border-bottom py-4">
              {cartState &&
                cartState?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex gap-10 mb-2 align-items-center"
                  >
                  </div>
                ))}
            </div>
            <div className="border-bottom py-4">
              <div className="d-flex justify-content-between align-items-center">
                <p className="total">Pending Balance</p>
                <p className="total-price">
                  Ugx. {totalAmount ? totalAmount : " wallet balance "}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 total">Remaining Product Purchases</p>
                <p className="mb-0 total-price"> {cartState?.length ? cartState?.length : 0}</p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom py-4">
              <h4 className="total">Current Pending Balance</h4>
              <h5 className="total-price">
                Ugx. {totalAmount ? totalAmount  : "0"}
              </h5>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Checkout;